import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Divider,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { useStore } from '../hooks/useStore';
import ItemCard from './ItemCard';
import OrderLinkCard from './OrderLinkCard';
import { observer } from 'mobx-react-lite';
import { LinkIcon } from '@chakra-ui/icons';
import Ticket from './Ticket';
import BillForm from './BillForm';

export const orderTag = {
  created: ['gray.200', 'Создана'],
  processing: ['orange.200', 'В обработке'],
  price: ['blue.200', 'Цена получена'],
  checked: ['yellow.200', 'Проверена'],
  waitbill: ['gray.200', 'Выставление счета'],
  invoice: ['green.200', 'Счет выставлен'],
  paid: ['teal.200', 'Оплачен'],
  stock: ['cyan.200', 'Прибыла на склад'],
  assembled: ['blue.200', 'Собран'],
  send: ['purple.200', 'Отправлен'],
  ontheway: ['pink.200', ''],
  confirm: ['green.200', 'Отправка подтверждена'],
  delivered: ['green.200', 'Доставлен'],
  issued: ['green.200', 'Выдан'],
  deleted: ['red.200', 'Удален'],
  modified: ['red.200', 'Изменен'],
  wrong: ['red.200', 'Несоответствие'],
};

const OrderCard = ({ order }) => {
  const [ticket, setTicket] = useState(false);
  const [bOpen, setB] = useState(false);

  const { store } = useStore();
  return (
    <>
      <Flex mb="10px" flexDir={['column', 'column', 'row']}>
        <Flex
          bg={orderTag[order.status][0]}
          textAlign="center"
          borderRight="1px"
          borderRightColor="gray.300"
          borderLeftRadius="md"
          borderRightRadius={['md', 'md', '0']}
          w={['auto', 'auto', '100px']}
          fontSize="xs"
          alignItems="center"
          justifyContent="center"
        >
          {orderTag[order.status][1]}
        </Flex>
        <AccordionItem flex="1">
          <h2>
            <AccordionButton as={Flex} gap={['10px', '10px', '20px']}>
              <Flex
                gap={['5px', '5px', '20px']}
                flexDirection={['column', 'column', 'row']}
              >
                <Box
                  as={rLink}
                  to={`/order/${order.id}`}
                  color="green"
                  fontWeight="bold"
                >
                  {order.code}
                </Box>
                <Box>{new Date(order.orderDate).toLocaleDateString()}</Box>
              </Flex>
              <Box flex="1" alignSelf="center"></Box>
              <Box>{order.comment}</Box>
              {!!order.convertedSumm && (
                <Box fontWeight="bold" color="green">
                  {order.convertedSumm} &#8381;
                </Box>
              )}
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel px={['5px', '10px', '20px']}>
            {order.items.map((item) => (
              <ItemCard key={item.id} item={item} status={order.status} />
            ))}
            {order.linkItems.map((link) => (
              <OrderLinkCard
                key={link.id}
                order={order}
                status={order.status}
                item={link}
              />
            ))}

            {!!order.linkItems?.length && (
              <Box fontSize="sm" p="10px" borderRadius="lg" bg="gray.100">
                Товары нужно доставить до пункта репака в Турции:
                <Text fontWeight="bold">
                  Kemalpaşa Mah. Atatürk Bulvarı Emlak Pasajı No: 30/1 Dükkan
                  No: 28 Fatih / İstanbul
                </Text>
              </Box>
            )}

            {order.sendDate && (
              <>
                <Divider mb="20px" />
                <Flex justifyContent="space-between">
                  <Box fontSize="sm">
                    {order.sendDate && (
                      <Box>
                        Дата отправки -{' '}
                        {new Date(order.sendDate).toLocaleDateString()}
                      </Box>
                    )}
                    {order.arriveDate && (
                      <Box>
                        Дата доставки в Россию -{' '}
                        {new Date(order.arriveDate).toLocaleDateString()}
                      </Box>
                    )}
                    {order.issDate && (
                      <Box>
                        Дата выдачи -{' '}
                        {new Date(order.issDate).toLocaleDateString()}
                      </Box>
                    )}
                  </Box>
                  <Box fontSize="sm">
                    {order.error && (
                      <Text color="red" fontWeight="strong">
                        Вес не соответствует
                      </Text>
                    )}
                    <Box
                      bg={order.error ? 'red.200' : 'transparent'}
                      p={order.error ? '8px' : '0'}
                    >
                      {order.sendWeight && (
                        <Box>Вес отправки - {order.sendWeight} кг</Box>
                      )}
                      {order.arriveWeight && (
                        <Box>Вес прибытия - {order.arriveWeight} кг</Box>
                      )}
                    </Box>
                    {order.shipPrice && (
                      <Flex gap="12px">
                        <Text>Сумма доставки - </Text>
                        <Text
                          fontWeight="bold"
                          color={order.shipPayed ? 'green' : 'red'}
                        >
                          {order.shipPrice}${' '}
                        </Text>
                        <Text
                          fontSize="xs"
                          color={order.shipPayed ? 'green' : 'red'}
                        >
                          {order.shipPayed ? 'Оплачено' : 'Не оплачено'}
                        </Text>
                      </Flex>
                    )}
                  </Box>
                </Flex>
              </>
            )}

            {['created', 'waitbill', 'invoice', 'wrong'].includes(
              order.status
            ) && (
              <>
                <Divider my="20px" />
                <Flex gap="20px">
                  <Spacer />
                  {order.status === 'created' && (
                    <Button
                      size="sm"
                      colorScheme="green"
                      isLoading={store.loading}
                      onClick={() => store.manageOrder(order.id)}
                    >
                      Manage
                    </Button>
                  )}

                  {order.status === 'waitbill' && (
                    <>
                      <Button
                        size="sm"
                        colorScheme="teal"
                        onClick={() => setB(true)}
                      >
                        Send bill
                      </Button>
                      <BillForm
                        order={order}
                        isOpen={bOpen}
                        onClose={() => setB(false)}
                      />
                    </>
                  )}

                  {order.status === 'invoice' && (
                    <Flex gap="20px">
                      {order?.bill?.ticketFile && (
                        <>
                          <Button
                            leftIcon={<LinkIcon />}
                            size="sm"
                            variant="ghost"
                            onClick={() => setTicket(true)}
                          >
                            Ticket
                          </Button>
                          <Ticket
                            isOpen={ticket}
                            onClose={() => setTicket(false)}
                            ticket={order.bill.ticketFile}
                          />
                        </>
                      )}
                      <Button
                        size="sm"
                        colorScheme="green"
                        isLoading={store.loading}
                        onClick={() => store.checkPay(order.id)}
                      >
                        Confirm pay
                      </Button>
                    </Flex>
                  )}

                  {order.status === 'wrong' && order.error && (
                    <Button colorScheme="green" isLoading={store.loading}>
                      Fixed
                    </Button>
                  )}
                </Flex>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Flex>
    </>
  );
};

export default observer(OrderCard);
