import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Image,
  Link,
  Text,
  HStack,
  Box,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
} from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import FileUpload from './FileUpload';

const SetLink = ({ opened, close, item }) => {
  const { store } = useStore();
  const [price, setPrice] = useState('');
  const [img, setImg] = useState(null);
  const [prew, setPrew] = useState(null);

  const fileHendler = (e) => {
    const img = e.target.files[0];
    setImg(img);
    const image = URL.createObjectURL(img);
    setPrew(image);
  };

  const onClose = () => {
    setPrice('');
    setImg(null);
    setPrew(null);
    close(false);
  };

  return (
    <>
      <Modal isOpen={opened} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add item information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="20px">
              <Link href={item.link} target="_blank">
                <HStack>
                  <LinkIcon />
                  <Text fontSize="xs" color="blue" noOfLines={1}>
                    {item.link}
                  </Text>
                </HStack>
              </Link>
              <Text mt="15px">{item.description}</Text>
            </Box>
            <Flex gap="20px">
              {prew ? (
                <Image objectFit="cover" boxSize="50px" src={prew} />
              ) : (
                <FileUpload accept={'image/*'} hendler={fileHendler}>
                  <Button size="sm">Upload image</Button>
                </FileUpload>
              )}
              <FormControl>
                <InputGroup size="sm">
                  <InputLeftAddon children="Item price" />
                  <Input
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <InputRightAddon children="$" />
                </InputGroup>
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={!img || !price}
              colorScheme="blue"
              mr={3}
              isLoading={store.loading}
              onClick={() => store.updateLink(item.id, { price, img }, onClose)}
            >
              Confirm
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(SetLink);
