import React, { useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Tag,
  Text,
  HStack,
  Link,
  Button,
} from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';
import SetLink from './SetLink';
import { orderItemTag } from './ItemCard';
import SendForm from './SendForm';

const OrderLinkCard = ({ item, status }) => {
  const [itemForm, setItemForm] = useState(false);
  return (
    <Box>
      <Flex gap="20px" mb="20px" alignItems="stretch">
        {!['send', 'delivered', 'issued', 'assembled'].includes(status) && (
          <Flex
            bg={orderItemTag[item.status][0]}
            fontSize="xs"
            borderRightRadius="md"
            borderLeftRadius={['md', 'md', '0']}
            px="10px"
            alignItems="center"
          >
            {orderItemTag[item.status][1]}
          </Flex>
        )}
        <Box>
          {!!item.img && (
            <Image objectFit="cover" boxSize="50px" src={item.img} />
          )}
        </Box>
        <Box flex="1">
          <Box>
            <Text color="orange" fontWeight="bold">
              {item.code}
            </Text>
            <Link href={item.link} target="_blank">
              <HStack>
                <LinkIcon />
                <Text fontSize="xs" color="blue" noOfLines={1} w="250px">
                  {item.link}
                </Text>
              </HStack>
            </Link>
          </Box>
          <Box>{item.description}</Box>
        </Box>
        {!!item.price && (
          <Box textAlign="center">
            <Text fontSize="sm">Item price</Text>
            <Text fontWeight="bold" color="green">
              {item.price} $
            </Text>
          </Box>
        )}
        <Box textAlign="center">
          <Text fontSize="sm">Count</Text>
          <Text fontWeight="bold">{item.count}</Text>
        </Box>

        {!!item.summ && (
          <Box textAlign="center">
            <Text fontSize="sm">Full price</Text>
            <Text fontWeight="bold" color="green">
              {item.summ} $
            </Text>
          </Box>
        )}

        <Flex alignItems="center">
          {item.status === 'wait confirm' && status !== 'created' && (
            <>
              <Button
                colorScheme="green"
                size="sm"
                onClick={() => setItemForm(true)}
              >
                Set price
              </Button>
              <SetLink opened={itemForm} close={setItemForm} item={item} />
            </>
          )}
          {item.status === 'checked' && status === 'paid' && (
            <>
              <Button
                colorScheme="green"
                size="sm"
                onClick={() => setItemForm(true)}
              >
                Send
              </Button>
              <SendForm opened={itemForm} onClose={setItemForm} item={item} />
            </>
          )}
        </Flex>
      </Flex>
      {['wrong', 'updated'].includes(item.status) && (
        <Flex mt="10px" ml="20px" gap="20px">
          <Text fontWeight="bold" fontSize="sm">
            Customer comment
          </Text>
          <Text>{item.wrong}</Text>
        </Flex>
      )}
    </Box>
  );
};

export default OrderLinkCard;
