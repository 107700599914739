import { makeAutoObservable } from 'mobx';
import CatEditService from '../service/CatEditService';

export default class CatEditStore {
  loading = false;
  categories = [];
  catToEdit = null;
  subcatToEdit = null;
  items = [];
  selectedItems = [];
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setError(err) {
    this.error = err;
  }

  setNames(subId = null) {
    if (!subId) {
      this.catName = '';
      this.subName = '';
    } else {
      const names = this.categories.map((cat) => cat.subcategory);
      console.log(names);
    }
  }

  setItems(itemsArr = []) {
    this.items = itemsArr;
  }

  setCategories(catArray = []) {
    this.categories = catArray;
  }

  setSelectCat(obj) {
    this.catToEdit = obj;
  }

  catSelect(id) {
    if (this.categories.length) {
      const cat = this.categories.find((item) => item.id === id);
      this.setSelectCat(cat);
    }
  }

  setSelectSubcat(obj) {
    this.subcatToEdit = obj;
    if (obj) {
      if (!obj.name) this.subcatToEdit.name = '';
      if (!obj.name_en) this.subcatToEdit.name_en = '';
      if (!obj.name_tr) this.subcatToEdit.name_tr = '';
    }
  }

  subcatSelect(id, parent) {
    if (this.categories.length) {
      const parentIndex = this.categories.findIndex(
        (item) => item.id === parent
      );
      const cat = this.categories[parentIndex].subcategory.find(
        (item) => item.id === id
      );
      cat.parent = parent;
      this.setSelectSubcat(cat);
    }
  }

  setCatValue(e) {
    this.catToEdit[e.target.name] = e.target.value;
  }
  setSubCatValue(e) {
    this.subcatToEdit[e.target.name] = e.target.value;
  }

  selectItem(id) {
    const find = this.selectedItems.includes(id);
    if (find) {
      this.selectedItems = this.selectedItems.filter((i) => i != id);
    } else {
      this.selectedItems.push(id);
    }
  }

  clearSelected() {
    this.selectedItems = [];
  }

  async getAllCategories() {
    this.setLoading(true);
    try {
      const { data } = await CatEditService.getAllCategories();
      this.setCategories(data);
    } catch (e) {
      console.log(e);
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateCat() {
    this.setLoading(true);
    try {
      const { id, name, name_en, name_tr } = this.catToEdit;
      const { data } = await CatEditService.updateCategory(id, {
        name,
        name_en,
        name_tr,
      });
      this.setSelectCat(null);
      this.setSelectSubcat(null);
      this.getAllCategories();
    } catch (e) {
      console.log(e);
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async deleteSubCat(id) {
    this.setLoading(true);
    try {
      const { data } = await CatEditService.deleteSubCat({ id });
      this.getAllCategories();
    } catch (e) {
      console.log(e);
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async addSubCat() {
    this.setLoading(true);
    try {
      const { data } = await CatEditService.addSubCategory(this.subcatToEdit);
      this.setSelectSubcat(null);
      this.getAllCategories();
    } catch (e) {
      console.log(e);
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getSubcatItems(id) {
    this.setLoading(true);
    try {
      const { data } = await CatEditService.getSubCategoryItems(id);
      this.setItems(data);
    } catch (e) {
      console.log(e);
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async moveItems(subcat) {
    this.setLoading(true);
    try {
      if (!this.selectedItems.length) return;
      const { data } = await CatEditService.moveItems({
        subcat,
        items: JSON.stringify(this.selectedItems),
      });

      const updateItems = this.items.filter(
        (item) => !this.selectedItems.includes(item.id)
      );
      this.setItems(updateItems);
      this.clearSelected();
    } catch (e) {
      console.log(e);
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
}
