import {
  Box,
  Button,
  Center,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from '../hooks/useStore';

const SmsForm = ({ registerStore }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [call, setCall] = useState(false);
  const [email, setEmail] = useState(false);
  const { user } = useStore();

  const getCall = async () => {
    await registerStore.resendCode();
    setCall(true);
  };

  const getEmail = async () => {
    await registerStore.resendCode('email');
    setEmail(true);
  };

  return (
    <Modal isOpen={registerStore.isEnterCode} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          {call
            ? email
              ? 'Enter the code from your email'
              : 'Enter the last 4 digits of the called number'
            : 'Enter SMS code'}
        </ModalHeader>
        <ModalBody>
          <Center>
            {registerStore.loading ? (
              <Spinner />
            ) : (
              <>
                <HStack>
                  <PinInput
                    onComplete={async (value) => {
                      await registerStore.checkPhone(value);
                      user.checkAuth();
                    }}
                    isInvalid={!!registerStore.message}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </>
            )}
          </Center>
          {!!registerStore.message && (
            <Box mt="20px" w="full" textAlign="center" color="red">
              {registerStore.message}
            </Box>
          )}
          <Box mt="20px" textAlign="center">
            {!call ? (
              <Text>
                No text message? Get your code by{' '}
                <Link color="blue" onClick={() => getCall()}>
                  phone call
                </Link>
              </Text>
            ) : (
              <Text>
                No one has called?{' '}
                <Link color="blue" onClick={() => getEmail()}>
                  Get a code
                </Link>{' '}
                at {registerStore.email}
              </Text>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Center mt="20px">
            <Button
              variant="ghost"
              size="xs"
              onClick={() => {
                registerStore.setEnterCode(false);
                setCall(false);
                setEmail(false);
              }}
            >
              Return
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default observer(SmsForm);
