import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CrmMainPage from '../components/CrmMainPage';
import Login from '../components/Login';
import MainPage from '../components/MainPage';
import NoAccess from '../components/NoAccess';
import OrdersPage from '../components/OrdersPage';
import OrderViewPage from '../components/OrderViewPage';
import Register from '../components/Register';
import UserSetup from '../components/UserSetup';
import VendorsPage from '../components/VendorsPage';
import VendorViewPage from '../components/VendorViewPage';
import VendorEditPage from '../components/VendorEditPage';
import CatViewPage from '../components/CatViewPage';
import SubcatViewPage from '../components/SubcatViewPage';

const useRoutes = (isAuth, role) => {
  if (isAuth) {
    if (!role) {
      return (
        <Routes>
          <Route path="/noaccess" element={<NoAccess />} />
          <Route path="*" element={<Navigate to="/noaccess" />} />
        </Routes>
      );
    }
    return (
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route index element={<CrmMainPage />} />
          <Route path="setup" element={<UserSetup />} />
          <Route path="vendors" element={<VendorsPage />} />
          <Route path="vendor/:id" element={<VendorViewPage />} />
          <Route path="vendor/:id/edit" element={<VendorEditPage />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="order/:id" element={<OrderViewPage />} />
          <Route path="categories" element={<CatViewPage />} />
          <Route path="category/:catId/:id" element={<SubcatViewPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/enter" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<Navigate to="/enter" />} />
    </Routes>
  );
};

export default useRoutes;
