import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

const VendorContacts = ({ vendor }) => {
  return (
    <>
      {vendor.manager.tel && (
        <Flex gap="20px">
          <Box w="70px">Manager</Box>
          <Box>
            <Text fontWeight="bold">{vendor.manager.name}</Text>
            <Text>+{vendor.manager.tel}</Text>
          </Box>
        </Flex>
      )}

      {vendor.ceo.tel && (
        <Flex gap="20px">
          <Box w="70px">CEO</Box>
          <Box>
            <Text fontWeight="bold">{vendor.ceo.name}</Text>
            <Text>+{vendor.ceo.tel}</Text>
          </Box>
        </Flex>
      )}

      <Box>{vendor.tel}</Box>
    </>
  );
};

export default VendorContacts;
