import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Center, Spinner } from '@chakra-ui/react';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import ItemCard from './ItemCard';

const OrderViewPage = () => {
  const { id } = useParams();
  const { store } = useStore();

  useEffect(() => {
    store.getOrder(id);
    return () => {
      store.setOrder(null);
    };
  }, []);

  if (!store.order)
    return (
      <Center flex="1">
        <Spinner thickness="4px" size="xl" color="green" />
      </Center>
    );
  return (
    <Box>
      <Heading>
        {store.order.code} -{' '}
        {new Date(store.order.orderDate).toLocaleDateString()} -{' '}
        {store.order.status}
      </Heading>
      {store.order.items.map((item) => (
        <ItemCard key={item.id} item={item} />
      ))}
    </Box>
  );
};

export default observer(OrderViewPage);
