import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Center,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../hooks/useStore';

function CatEditForm() {
  const { catEdit } = useStore();

  return (
    <Modal
      isOpen={!!catEdit.catToEdit}
      onClose={() => catEdit.setSelectCat(null)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rename category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Name RU</FormLabel>
            <Input
              name="name"
              value={catEdit.catToEdit?.name}
              onChange={(e) => catEdit.setCatValue(e)}
            />
          </FormControl>
          <FormControl my="20px">
            <FormLabel>Name EN</FormLabel>
            <Input
              name="name_tr"
              value={catEdit.catToEdit?.name_tr}
              onChange={(e) => catEdit.setCatValue(e)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Name TR</FormLabel>
            <Input
              name="name_en"
              value={catEdit.catToEdit?.name_en}
              onChange={(e) => catEdit.setCatValue(e)}
            />
          </FormControl>
          <Center my="20px">
            <Button
              colorScheme="green"
              onClick={() => catEdit.updateCat()}
              isLoading={catEdit.loading}
            >
              Save
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default observer(CatEditForm);
