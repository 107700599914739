import React, { useEffect } from 'react';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Tag,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks/useStore';
import OrderMinCard from './OrderMinCard';
import VendorCard from './VendorCard';
import { Link as rLink } from 'react-router-dom';

const CrmMainPage = () => {
  const { store } = useStore();

  useEffect(() => {
    store.getOrders({ status: 'created' });
    store.getVendors();
    return () => {
      store.setOrders([]);
    };
  }, []);

  // if (!store.orders.length && store.loading)
  //   return (
  //     <Center flex="1">
  //       <Spinner thickness="4px" size="xl" color="green" />
  //     </Center>
  //   );

  return (
    <>
      <Flex
        gap="20px"
        flexDirection={['column-reverse', 'column-reverse', 'row']}
      >
        <Box bg="white" flex="1" p="20px" borderRadius="md" boxShadow="lg">
          <Flex pb="10px">
            <Heading as={rLink} to="/vendors" size="sm" flex="1">
              Vendors
            </Heading>
            {!!store.newVendors && (
              <Tag size="sm" colorScheme="red" borderRadius="full">
                New vendors - {store.newVendors}
              </Tag>
            )}
          </Flex>
          <Divider mb="20px" />
          {!store.vendors.length ? (
            <Center flex="1">
              <Spinner thickness="4px" size="md" color="green" />
            </Center>
          ) : (
            store.vendors.map((vendor) => (
              <VendorCard key={vendor.mid} vendor={vendor} />
            ))
          )}
        </Box>
        <Box bg="white" flex="1" p="20px" borderRadius="md" boxShadow="lg">
          <Flex pb="10px" gap="20px">
            <Heading as={rLink} to="/orders" size="sm">
              New orders
            </Heading>
            {!!store.orders.length && (
              <Tag colorScheme="red">{store.orders.length}</Tag>
            )}
          </Flex>
          <Divider mb="20px" />
          {store.orders.length
            ? store.orders.map((item) => (
                <OrderMinCard key={item.id} order={item} />
              ))
            : 'No orders'}
        </Box>
        <Box></Box>
      </Flex>
    </>
  );
};

export default observer(CrmMainPage);
