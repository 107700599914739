import React from 'react';
import { Box, Flex, Tag, Button, Spacer } from '@chakra-ui/react';
import { Link as rLink } from 'react-router-dom';
import { useStore } from '../hooks/useStore';
import { orderTag } from './OrderCard';
import { observer } from 'mobx-react-lite';

const OrderMinCard = ({ order }) => {
  const { store } = useStore();
  return (
    <Box mb="20px">
      <Flex gap="20px" justifyContent="space-between">
        <Box
          as={rLink}
          to={`/order/${order.id}`}
          color="green"
          fontWeight="bold"
        >
          {order.code}
        </Box>
        <Box>{new Date(order.orderDate).toLocaleDateString()}</Box>
        <Box>
          <Tag
            colorScheme={orderTag[order.status]}
            size="sm"
            variant="solid"
            borderRadius="full"
          >
            {order.status}
          </Tag>
        </Box>
        <Spacer />
        {/* <Box>{order.summ}</Box> */}
        {order.status === 'ok' && (
          <Button
            size="xs"
            colorScheme="teal"
            onClick={() => store.sendBill(order.id)}
          >
            Send bill
          </Button>
        )}
        {order.status === 'created' && (
          <Button
            size="xs"
            colorScheme="green"
            onClick={() => {
              store.manageOrder(order.id);
            }}
          >
            Manage
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default observer(OrderMinCard);
