import React from 'react';
import {
  Box,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tag,
  Button,
} from '@chakra-ui/react';
import { tag } from './VendorViewPage';
import VendorData from './VendorData';
import { Link as rLink } from 'react-router-dom';

const VendorPrewCard = ({ vendor }) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            {vendor.name}
          </Box>
          <Tag colorScheme={tag[vendor.status]} borderRadius="full" mr="20px">
            {vendor.status}
          </Tag>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <VendorData vendor={vendor} />
        <Button
          as={rLink}
          to={`/vendor/${vendor.mid}`}
          colorScheme="green"
          size="sm"
          mt="20px"
        >
          Manage
        </Button>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default VendorPrewCard;
