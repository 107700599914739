import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
  VStack,
  Button,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import useValidation from '../hooks/useValidation';
import useMessage from '../hooks/useMessage';
import { useParams, Link as rLink, useNavigate } from 'react-router-dom';
import { useStore } from '../hooks/useStore';

const VendorEditPage = () => {
  const { id } = useParams();
  const { store } = useStore();
  const navigate = useNavigate();

  const name = useValidation(store.vendor?.name, { isEmpty: true });
  const ur_type = useValidation(store.vendor?.ur_type, { isEmpty: true });
  const inn = useValidation(store.vendor?.inn, { isEmpty: true });
  const rs = useValidation(store.vendor?.rs, { isEmpty: true });
  // const tax_com = useValidation(vendorStore.form.tax_com, { isEmpty: true });
  const actual_adress = useValidation(store.vendor?.actual_adress, {
    isEmpty: true,
  });
  const registered_adress = useValidation(store.vendor?.registered_adress, {
    isEmpty: true,
  });
  const email = useValidation(store.vendor?.email, {
    isEmpty: true,
    isEmail: true,
  });
  const index = useValidation(store.vendor?.index, { isEmpty: true });
  const country = useValidation(store.vendor?.country, { isEmpty: true });
  const city = useValidation(store.vendor?.city, { isEmpty: true });
  const district = useValidation(store.vendor?.district, { isEmpty: true });
  const ceo_name = useValidation(store.vendor?.ceo_name, { isEmpty: true });
  const ceo_tel = useValidation(store.vendor?.ceo_tel, { isEmpty: true });

  const [copy, setCopy] = useState(false);

  useEffect(() => {
    store.getVendor(id);
  }, []);

  const copyAddress = () => {
    setCopy(!copy);
  };

  const changeHandler = (e) => {
    store.setVendorData(e.target.name, e.target.value);
    if (copy && e.target.name == 'actual_adress') {
      store.setVendorData('registered_adress', e.target.value);
    }
  };

  return (
    <Box p={['15px', '15px', '30px', '30px']} bg="white" borderRadius="md">
      <VStack gap="10px">
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.name}
            isDisabled={store.loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea
            name="description"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.description}
            isDisabled={store.loading}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>UR type</FormLabel>
          <Select
            name="ur_type"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.ur_type}
            isDisabled={store.loading}
          >
            <option value="Gerçek Kişi Tacir">Gerçek Kişi Tacir</option>
            <option value="İrtibat Bürosu">İrtibat Bürosu</option>
            <option value="Yabancı Şirket Şubesi">Yabancı Şirket Şubesi</option>
            <option value="Adi Ortaklık">Adi Ortaklık</option>
            <option value="Kooperatif">Kooperatif</option>
            <option value="Kollektif Şirket">Kollektif Şirket</option>
            <option value="Komandit Şirket">Komandit Şirket</option>
            <option value="Anonim Şirket">Anonim Şirket</option>
            <option value="Limited Şirket">Limited Şirket</option>
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>INN</FormLabel>
          <Input
            type="number"
            name="inn"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.inn}
            isDisabled={store.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>RS</FormLabel>
          <Input
            type="number"
            name="rs"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.rs}
            isDisabled={store.loading}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Actual address</FormLabel>
          <Input
            type="text"
            name="actual_adress"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.actual_adress}
            isDisabled={store.loading}
          />
          <Checkbox onChange={copyAddress}>
            The legal address is the same as the actual address
          </Checkbox>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Registered address</FormLabel>
          <Input
            type="text"
            name="registered_adress"
            placeholder=""
            onChange={changeHandler}
            value={
              copy
                ? store.vendor?.actual_adress
                : store.vendor?.registered_adress
            }
            isDisabled={store.loading || copy}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Website</FormLabel>
          <InputGroup>
            <InputLeftAddon children="https://" />
            <Input
              type="text"
              name="site"
              placeholder=""
              onChange={changeHandler}
              value={store.vendor?.site}
              isDisabled={store.loading}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>E-mail</FormLabel>
          <Input
            type="text"
            name="email"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.email}
            isDisabled={store.loading}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>ZIP code</FormLabel>
          <Input
            type="text"
            name="index"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.index}
            isDisabled={store.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Country</FormLabel>
          <Input
            type="text"
            name="country"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.country}
            isDisabled={store.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>City</FormLabel>
          <Input
            type="text"
            name="city"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.city}
            isDisabled={store.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>District</FormLabel>
          <Input
            type="text"
            name="district"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.district}
            isDisabled={store.loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Manager name</FormLabel>
          <Input
            type="text"
            name="manager_name"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.manager_name}
            isDisabled={store.loading}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Manager phone</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+" />
            <Input
              type="number"
              name="manager_tel"
              placeholder=""
              onChange={changeHandler}
              value={store.vendor?.manager_tel}
              isDisabled={store.loading}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>CEO name</FormLabel>
          <Input
            type="text"
            name="ceo_name"
            placeholder=""
            onChange={changeHandler}
            value={store.vendor?.ceo_name}
            isDisabled={store.loading}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>CEO phone</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+" />
            <Input
              type="number"
              name="ceo_tel"
              placeholder=""
              onChange={changeHandler}
              value={store.vendor?.ceo_tel}
              isDisabled={store.loading}
            />
          </InputGroup>
        </FormControl>

        <Flex gap="20px">
          <Button
            colorScheme="green"
            w="100px"
            onClick={async () => {
              try {
                await store.updateVendor();
                navigate(`/vendor/${id}`);
              } catch (e) {
                console.log(e);
              }
            }}
            isLoading={store.loading}
            isDisabled={
              name.err ||
              ur_type.err ||
              inn.err ||
              rs.err ||
              // tax_com.err ||
              actual_adress.err ||
              registered_adress.err ||
              email.err ||
              // tel.err ||
              index.err ||
              country.err ||
              city.err ||
              district.err ||
              ceo_name.err ||
              ceo_tel.err
            }
          >
            Save
          </Button>
          <Button as={rLink} to={`/vendor/${id}`} colorScheme="red" w="100px">
            Cancel
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default observer(VendorEditPage);
