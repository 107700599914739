import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Checkbox,
  Image,
  Button,
  FormControl,
  Select,
} from '@chakra-ui/react';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import useMessage from '../hooks/useMessage';

function SubcatViewPage() {
  const { id, catId } = useParams();
  const { catEdit } = useStore();
  const [moveSubCat, setMoveSubCat] = useState(0);
  const [catName, setCatName] = useState('');
  const [subcatName, setSubcatName] = useState('');

  console.log(catEdit);

  useEffect(() => {
    return () => {
      catEdit.clearSelected();
      catEdit.setItems();
    };
  }, []);

  useEffect(() => {
    catEdit.getSubcatItems(id);
    const cIndex = catEdit.categories.findIndex((c) => c.id == catId);
    if (cIndex !== -1) {
      setCatName(catEdit.categories[cIndex].name);
      const sIndex = catEdit.categories[cIndex].subcategory.findIndex(
        (c) => c.id == id
      );
      if (sIndex !== -1) {
        setSubcatName(catEdit.categories[cIndex].subcategory[sIndex].name);
      }
    }
  }, [id]);

  useMessage(catEdit.error, 'error', (...attr) => catEdit.setError(...attr));

  return (
    <Box p={['15px', '15px', '30px', '30px']} borderRadius="md" bg="white">
      <Heading>{catName}</Heading>
      <Heading size="md" ml="20px">
        {subcatName}
      </Heading>

      <Flex
        mt="30px"
        p="5px"
        borderRadius="10px"
        bg="#f4fae3"
        gap="20px"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Box flex="1" textAlign="right">
          Move to
        </Box>

        <FormControl w="auto">
          <Select
            name="cat"
            placeholder="Select category to move"
            isDisabled={!catEdit.selectedItems.length}
            value={moveSubCat}
            onChange={(e) => setMoveSubCat(e.target.value)}
          >
            {catEdit.categories.map((cat) => (
              <optgroup key={cat.name} label={cat.name}>
                {cat.subcategory.map((sub) => (
                  <option key={sub.id} value={sub.id}>
                    {sub.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
        </FormControl>

        <Button
          colorScheme="green"
          isDisabled={!catEdit.selectedItems.length}
          isLoading={catEdit.loading}
          onClick={() => catEdit.moveItems(moveSubCat)}
        >
          Move
        </Button>
      </Flex>

      <Box mt="30px">
        {catEdit.items.map((item) => (
          <Flex
            key={item.id}
            gap="20px"
            alignItems="center"
            p="5px"
            borderRadius="10px"
            bg="#f4fae3"
            mb="20px"
          >
            <Flex p="20px">
              <Checkbox
                onChange={() => {
                  catEdit.selectItem(item.id);
                }}
              ></Checkbox>
            </Flex>
            <Box>
              {!!item.images.length && (
                <Image
                  objectFit="cover"
                  boxSize="60px"
                  src={item.images[0].link}
                />
              )}
            </Box>
            <Box flex="2">{item.name}</Box>
            <Box flex="3" fontSize="sm">
              {item.description}
            </Box>
            <Box flex="1">{item.destination}</Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
}

export default observer(SubcatViewPage);
