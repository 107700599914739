import React from 'react';
import {
  Box,
  Tag,
  Flex,
  IconButton,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { Link as rLink } from 'react-router-dom';
import { tag } from './VendorViewPage';

const VendorCard = ({ vendor }) => {
  return (
    <>
      <Flex
        px="20px"
        py="4px"
        borderRadius="md"
        _hover={{ bg: 'gray.100' }}
        alignItems="center"
        gap="20px"
      >
        <Box as={rLink} to={`/vendor/${vendor.mid}`} flex="1">
          {vendor.name}
        </Box>
        <Box>
          <Tag borderRadius="full" colorScheme={tag[vendor.status]}>
            {vendor.status}
          </Tag>
        </Box>
        <Box>
          <Popover>
            <PopoverTrigger>
              <IconButton icon={<ViewIcon />} size="sm" />
            </PopoverTrigger>
            <PopoverContent p="10px">
              <Box mb="10px">
                <Box as="b">Description</Box>
                <Box>{vendor.description}</Box>
              </Box>
              <Box mb="10px">
                <Box as="b">INN</Box>
                <Box>{vendor.inn}</Box>
              </Box>
              <Box mb="10px">
                <Box as="b">RS</Box>
                <Box>{vendor.rs}</Box>
              </Box>
              <Box mb="10px">
                <Box as="b">UR type</Box>
                <Box>{vendor.ur_type}</Box>
              </Box>
              <Box mb="10px">
                <Box as="b">Site</Box>
                <Box>{vendor.site}</Box>
              </Box>
              <Box mb="10px">
                <Box as="b">CEO name</Box>
                <Box>{vendor.ceo_name}</Box>
              </Box>
              <Box mb="10px">
                <Box as="b">CEO phone</Box>
                <Box>{vendor.ceo_tel}</Box>
              </Box>
            </PopoverContent>
          </Popover>
        </Box>
      </Flex>
      <Divider />
    </>
  );
};

export default VendorCard;
