import { Icon } from '@chakra-ui/react';
import React from 'react';

const OKicon = (props) => {
  return (
    <Icon viewBox="-5 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m9.456 17.441c1.363-.316 2.566-.83 3.647-1.518l-.047.028c.498-.329.822-.887.822-1.52 0-1.003-.813-1.816-1.816-1.816-.348 0-.673.098-.949.267l.008-.005c-1.184.751-2.625 1.196-4.17 1.196s-2.987-.446-4.202-1.215l.032.019c-.274-.175-.608-.279-.967-.279-.642 0-1.206.334-1.529.837l-.004.007s0 0 0 .005c-.175.274-.279.608-.279.967 0 .643.335 1.207.839 1.529l.007.004c1.034.661 2.237 1.174 3.522 1.473l.078.015-3.469 3.465c-.332.327-.538.781-.538 1.284 0 .489.195.932.51 1.256l.03.03c.326.327.777.529 1.275.529s.949-.202 1.275-.529l3.421-3.404 3.4 3.406c.328.328.782.532 1.283.532 1.002 0 1.814-.812 1.814-1.814 0-.501-.203-.954-.531-1.283l-3.461-3.466zm-2.505-5.052c3.418-.003 6.188-2.775 6.188-6.194 0-3.421-2.773-6.194-6.194-6.194s-6.194 2.773-6.194 6.194v.006c.007 3.419 2.78 6.188 6.2 6.188zm0-8.758c1.417 0 2.565 1.148 2.565 2.565s-1.148 2.565-2.565 2.565c-1.415 0-2.563-1.146-2.565-2.561 0-.001 0-.001 0-.002 0-1.417 1.148-2.566 2.565-2.567z"
      />
    </Icon>
  );
};

export default OKicon;
