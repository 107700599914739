import { Grid, Heading, Spinner, Center } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import CatViewList from './CatViewList';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import CatEditForm from './CatEditForm';
import SubCatEditForm from './SubCatEditForm';
import useMessage from '../hooks/useMessage';

function CatViewPage() {
  const { catEdit } = useStore();

  useEffect(() => {
    catEdit.getAllCategories();

    return () => {
      // catEdit.setCategories();
    };
  }, []);

  useMessage(catEdit.error, 'error', (...attr) => catEdit.setError(...attr));

  return (
    <>
      <Heading mb="30px">Categories management</Heading>

      {catEdit.loading ? (
        <Center flex="1">
          <Spinner thickness="4px" size="md" color="green" />
        </Center>
      ) : (
        <Grid gap="20px" gridTemplateColumns="repeat(3, 1fr)">
          {catEdit.categories.map((cat) => (
            <CatViewList key={cat.id} category={cat} store={catEdit} />
          ))}
        </Grid>
      )}

      <CatEditForm />
      <SubCatEditForm />
    </>
  );
}

export default observer(CatViewPage);
