import { makeAutoObservable } from 'mobx';
import AuthService from '../service/AuthService';
import CrmService from '../service/CrmService';
import UserService from '../service/UserService';

export default class Store {
  isAuth = false;
  isManager = false;
  loading = false;
  error = null;
  userData = null;
  myOrders = [];
  orders = [];
  order = null;
  vendors = [];
  vendor = null;
  messages = [];
  message = null;
  stockImages = [];
  payData = [];

  constructor() {
    makeAutoObservable(this);
  }

  get newVendors() {
    return this.vendors.filter((item) => item.status === 'created').length;
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setManager(bool) {
    this.isManager = bool;
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setError(err) {
    this.error = err;
  }

  setMessage(msg) {
    this.message = msg;
  }

  setUserData(user) {
    this.userData = user;
  }

  setOrders(orders) {
    this.orders = orders;
  }

  setOrder(order) {
    this.order = order;
  }

  setStockImages(images = []) {
    this.stockImages = images;
  }

  setOrderStatus(order) {
    const idx = this.orders.findIndex((item) => item.id === order.id);
    this.orders[idx] = order;
  }

  addOrders(arr) {
    const updateOrder = this.orders.filter((item) => item.id !== arr.id);
    updateOrder.push(arr);
    this.orders = updateOrder;
  }

  setVendors(vendors) {
    this.vendors = vendors;
  }

  //updateVendor(vendor) {}

  setVendor(vendor) {
    this.vendor = { ...this.vendor, ...vendor };
  }

  setVendorData(name, value) {
    this.vendor[name] = value;
  }

  setPayData(data) {
    this.payData = data;
  }

  async login(login, password) {
    this.setLoading(true);
    try {
      const { data } = await AuthService.login(login, password);
      localStorage.setItem('ctk', data.accessToken);
      this.checkAuth();
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async logout() {
    this.setLoading(true);
    try {
      await AuthService.logout();
      localStorage.removeItem('ctk');
      this.setAuth(false);
      this.setUserData(null);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const { data } = await UserService.getCurrent();
      const { vendor, ...user } = data;
      if (user.role && (user.role === 'manager' || user.role === 'admin')) {
        this.setManager(true);
        //   return window.location.replace('http://localhost:3006');
      }
      this.setUserData(user);
      this.setAuth(true);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getOrders(search) {
    this.setLoading(true);
    try {
      const { data } = await CrmService.getOrders(search);
      this.setOrders(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
  async getOrder(id) {
    //WRITE !!!
    this.setLoading(true);
    try {
      const { data } = await CrmService.getOrder(id);
      this.setOrder(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getVendors(filter = {}) {
    this.setLoading(true);
    try {
      const { data } = await CrmService.getVendors(filter);
      this.setVendors(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getVendor(id) {
    this.setLoading(true);
    try {
      const { data } = await CrmService.getVendor(id);
      this.setVendor(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getVendorItems(vid, search = {}) {
    try {
      const { data } = await CrmService.getVendorItems(vid, search);
      return data;
    } catch (e) {
      this.setError(e);
    }
  }

  async updateVendor() {
    this.setLoading(true);
    try {
      const { mid: id, ...vendorData } = this.vendor;
      const { data } = await CrmService.updateVendor(id, vendorData);
      //this.setVendor();
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async publicateItem(id, vid, show) {
    try {
      const { data } = await CrmService.showHideItem(id, vid, show);
      if (data.message && data.message === 'OK') {
        return true;
      }
    } catch (e) {
      this.setError(e);
    }
  }

  async confirmVendor(id) {
    this.setLoading(true);
    try {
      await CrmService.confirmVendor(id);
      this.setVendor({ status: 'active' });
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async manageOrder(id) {
    this.setLoading(true);
    try {
      const { data } = await CrmService.manageOrder(id);
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async sendBill(oId, billData) {
    this.setLoading(true);
    try {
      const { data } = await CrmService.sendBill(oId, billData);
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateLink(id, linkData, callback = () => {}) {
    this.setLoading(true);
    try {
      if (linkData.code) {
        const { data } = await CrmService.sendItem(id, linkData);
        this.setOrderStatus(data);
      } else {
        const { data } = await CrmService.updateLink(id, linkData);
        this.setOrderStatus(data);
      }
      callback();
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkPay(oId) {
    this.setLoading(true);
    try {
      const { data } = await CrmService.checkPay(oId);
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateUser(userData) {
    this.setLoading(true);
    try {
      const { data } = await UserService.update(userData);
      this.setUserData(data.data);
      this.setMessage(data.message);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async resendEmailLink(userData) {
    this.setLoading(true);
    try {
      const { data } = await UserService.resendEmailLink(userData);
      this.setMessage(data.message);
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getStockImages(id) {
    try {
      const { data } = await CrmService.getStockImages(id);
      this.setStockImages(data);
    } catch (e) {
      this.setError(e);
    }
  }

  async getPayData() {
    try {
      if (!this.payData.length) {
        const { data } = await CrmService.getPayData();
        this.setPayData(data);
      }
    } catch (e) {
      this.setError(e);
    }
  }

  async fixItem(id) {
    try {
      const { data } = await CrmService.fixItem(id);
      this.setOrderStatus(data);
    } catch (e) {
      this.setError(e);
    }
  }
}
