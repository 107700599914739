import { Box, Flex, Tag, Image, Text, Link } from '@chakra-ui/react';
import React from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

export default function VendorItemCard({ item, pub }) {
  return (
    <Flex
      mb="30px"
      gap="10px"
      as={Link}
      href={item.show ? `https://turkpazar.ru/item/${item.id}` : undefined}
      isExternal={item.show}
      bg="green.50"
      _hover={{ bg: 'green.100', textDecoration: 'none' }}
    >
      <Tag bg={item.show ? 'green.500' : 'red.500'} onClick={pub}>
        {item.show ? <ViewIcon color="white" /> : <ViewOffIcon color="white" />}
      </Tag>
      <Box>
        {!!item.images.length && (
          <Image objectFit="cover" boxSize="60px" src={item.images[0].link} />
        )}
      </Box>
      <Box flex="2">
        <Text>{item.name}</Text>
      </Box>
      <Box flex="1" fontSize="xs">
        <Box>
          Category - <Text as="b">{item.price} $</Text>
        </Box>
        <Box>
          Subcategory - <Text as="b">{item.fullConvertedPrice} &#8381;</Text>
        </Box>
        <Box>
          Destination - <Text as="b">{item.fullConvertedPrice} &#8381;</Text>
        </Box>
      </Box>
      <Box flex="1">
        <Box>
          Vendors price - <Text as="b">{item.price} $</Text>
        </Box>
        <Box>
          Catalog price - <Text as="b">{item.fullConvertedPrice} &#8381;</Text>
        </Box>
      </Box>
    </Flex>
  );
}
