import React, { useEffect } from 'react';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Heading, Box, Accordion } from '@chakra-ui/react';
import VendorPrewCard from './VendorPrewCard';

const VendorsPage = () => {
  const { store } = useStore();

  return (
    <Box p={['15px', '15px', '30px', '30px']} borderRadius="md" bg="white">
      <Heading mb="30px">Vendors</Heading>
      <Accordion allowToggle>
        {store.vendors.map((item) => (
          <VendorPrewCard key={item.mid} vendor={item} />
        ))}
      </Accordion>
    </Box>
  );
};

export default observer(VendorsPage);
