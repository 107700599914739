import {
  Avatar,
  Box,
  Text,
  Flex,
  FormControl,
  Input,
  FormLabel,
  Button,
  Center,
  Badge,
  Tag,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from '../hooks/useStore';
import UserService from '../service/UserService';
import FileUpload from './FileUpload';
import MessageBox from './MessageBox';

const colors = {
  active: 'green',
  blocked: 'red',
};

const UserSetup = () => {
  const { store } = useStore();
  const [user, setUser] = useState({
    avatar: store.userData.avatar,
    email: store.userData.email,
    login: store.userData.login || '',
    name: store.userData.name || '',
    phone: store.userData.phone,
    second_name: store.userData.second_name || '',
  });

  const [check, setCheck] = useState({ email: false, login: false });

  const [avatar, setAvatar] = useState(null);

  const fileHendler = (e) => {
    const img = e.target.files[0];
    setUser({ ...user, avatar: img });
    const image = URL.createObjectURL(img);
    setAvatar(image);
  };

  const checkField = async (e) => {
    const { data } = await UserService.checkField({
      [e.target.name]: e.target.value,
    });
    setCheck({ ...check, [e.target.name]: data.find });
  };

  const sendHandler = async () => {
    store.updateUser(user);
  };

  return (
    <Flex
      gap="20px"
      flexDirection={['column', 'column', 'row']}
      alignItems={['stretch', 'stretch', 'start']}
    >
      <Flex
        bg="white"
        gap="20px"
        borderRadius="md"
        p="20px"
        align="center"
        minW={['auto', 'auto', '200px']}
        flexDirection={['row', 'row', 'column']}
      >
        <Avatar size="lg" src={avatar || user.avatar} />
        <Box textAlign={['left', 'left', 'center']}>
          <Box>
            <Text as="b">{user.login || user.email}</Text>
          </Box>
          <Box>
            Status:{' '}
            <Tag
              borderRadius="full"
              colorScheme={colors[store.userData.status]}
            >
              {store.userData.status}
            </Tag>
          </Box>
        </Box>
      </Flex>
      <Flex
        bg="white"
        borderRadius="md"
        flex="1"
        p={['15px', '15px', '40px']}
        flexDirection={['column', 'column', 'row']}
      >
        <Box textAlign={['center']}>
          <Avatar
            src={avatar || user.avatar}
            mb="30px"
            boxSize={['100px', '150px', '200px']}
          />
          <FileUpload accept={'image/*'} hendler={fileHendler}>
            <Button>Upload image</Button>
          </FileUpload>
        </Box>
        <Box ml={['0', '0', '40px']} flex="1">
          <FormControl mb="20px">
            <FormLabel>Login</FormLabel>
            <Input
              type="text"
              name="login"
              bg={check.login ? 'red.100' : ''}
              errorBorderColor="red.300"
              value={user.login}
              onChange={(e) => {
                checkField(e);
                setUser({ ...user, [e.target.name]: e.target.value });
              }}
              isDisabled={store.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={user.name}
              onChange={(e) => {
                setUser({ ...user, [e.target.name]: e.target.value });
              }}
              isDisabled={store.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>Second name</FormLabel>
            <Input
              type="text"
              name="second_name"
              value={user.second_name}
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
              isDisabled={store.loading}
            />
          </FormControl>
          <FormControl mb="20px">
            <FormLabel>
              E-mail{' '}
              {store.userData.emailConfirm || (
                <Badge colorScheme="red">Not confirmed</Badge>
              )}
            </FormLabel>
            <Input
              type="text"
              name="email"
              bg={check.email ? 'red.100' : ''}
              errorBorderColor="red.300"
              value={user.email}
              onChange={(e) => {
                checkField(e);
                setUser({ ...user, [e.target.name]: e.target.value });
              }}
              isDisabled={store.loading}
            />
            {store.userData.emailConfirm || (
              <Button
                onClick={() => store.resendEmailLink()}
                size="xs"
                w="full"
              >
                Confirm E-mail
              </Button>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Phone</FormLabel>
            <Input
              type="text"
              name="phone"
              value={user.phone}
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
              isDisabled={store.loading}
            />
          </FormControl>
        </Box>
        <Button
          ml={['auto', 'auto', '40px']}
          mt={['20px', '20px', '0']}
          w={['full', 'full', 'auto']}
          colorScheme="green"
          onClick={sendHandler}
          isLoading={store.loading}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default observer(UserSetup);
