import { useEffect, useState } from 'react';

const useTrim = (page, allPages, dia = 5) => {
  const [viewPages, setViewPages] = useState([]);
  const [showLeft, setLeft] = useState(false);
  const [showRight, setRight] = useState(false);

  useEffect(() => {
    let start;
    let end;

    if (allPages.length < dia) setViewPages(allPages);
    else {
      start = page - dia / 2;
      start = start < 0 ? 0 : start;
      end = start + dia;

      setViewPages(allPages.slice(start, end));
    }

    if (start > 0) setLeft(true);
    else setLeft(false);

    if (end < allPages.length) setRight(true);
    else setRight(false);
  }, [page, allPages]);

  return [viewPages, showLeft, showRight];
};

export default useTrim;
