import React, { useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverFooter,
} from '@chakra-ui/react';
import { BiBuildings, BiComment } from 'react-icons/bi';
import VendorContacts from './VendorContacts';
import StockImages from './StockImages';
import { useStore } from '../hooks/useStore';

export const orderItemTag = {
  'wait confirm': ['gray.200', 'Ждет подтверждения'],
  modified: ['orange.200', 'Изменен'],
  checked: ['green.200', 'Подтвержден'],
  deleted: ['red.200', 'Удален'],
  send: ['teal.200', 'Отправлен'],
  recive: ['cyan.200', 'На складе'],
  updated: ['green.200', 'Обновлен'],
  wrong: ['red.200', 'Несоответствие'],
  mismatch: ['orange.200', 'Несоответствие на складе'],
};

const ItemCard = ({ item, status }) => {
  const { store } = useStore();
  const wrong = item.status === 'wrong';
  const [photos, setPhotos] = useState(false);

  return (
    <Box
      mb="20px"
      bg={item.status === 'modified' ? 'orange.100' : wrong && 'orange.100'}
      py="5px"
      pl={['5px', '5px', '0']}
      pr="5px"
      borderRightRadius="md"
      borderLeftRadius={['md', 'md', '0']}
    >
      <Flex
        gap={['10px', '10px', '20px']}
        flexDir={['column', 'column', 'row']}
      >
        <Flex
          gap={['5px', '10px', '20px']}
          flexDir={['column', 'column', 'row']}
        >
          {!['send', 'delivered', 'issued', 'assembled'].includes(status) && (
            <Flex
              bg={orderItemTag[item.status][0]}
              fontSize="xs"
              borderRightRadius="md"
              borderLeftRadius={['md', 'md', '0']}
              px="10px"
              alignItems="center"
            >
              {orderItemTag[item.status][1]}
            </Flex>
          )}
          <Flex
            gap={['10px', '10px', '20px']}
            flexDir={['column', 'column', 'row']}
          >
            <Flex gap="20px">
              <Box>
                {!!item.images.length && (
                  <Image
                    objectFit="cover"
                    boxSize="50px"
                    src={item.images[0].link}
                  />
                )}
              </Box>
              <Box>
                <Box>{item.name}</Box>
                <Box fontSize="12px">
                  Упаковка: {item.pkg_x} x {item.pkg_y} x {item.pkg_z} Вес:{' '}
                  {item.pkg_w}
                </Box>
              </Box>
            </Flex>

            <Box>
              <Flex flexDir={['row', 'row', 'column']} gap="10px">
                <Text fontSize="xs">
                  Колличество в заказе - {item.orderCount}
                </Text>
                {item.factCount === 0 ? (
                  <Text>Нет в наличии</Text>
                ) : (
                  item.factCount &&
                  item.factCount < item.orderCount && (
                    <Text color="red" fontWeight="bold" fontSize="xs">
                      В наличии - {item.factCount}
                    </Text>
                  )
                )}

                {item.stockCount && (
                  <Text fontSize="xs">
                    Поступило на склад - {item.stockCount}
                  </Text>
                )}
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Flex
          flex="1"
          gap="20px"
          justifyContent={['space-between', 'flex-end', 'space-between']}
        >
          <Box>
            {item.stockDate && (
              <>
                <Button onClick={() => setPhotos(true)} size="xs" h="full">
                  Stock photos
                </Button>
                <StockImages
                  orderItem={item.id}
                  isOpen={photos}
                  onClose={() => setPhotos(false)}
                />
              </>
            )}
          </Box>
          {item.status === 'mismatch' && (
            <Button
              h="full"
              colorScheme="green"
              onClick={() => store.fixItem(item.id)}
            >
              Fixed
            </Button>
          )}

          <Flex gap="15px">
            {item.comment && (
              <Popover placement="left" mr="20px">
                <PopoverTrigger>
                  <IconButton
                    h="full"
                    colorScheme="orange"
                    icon={<BiComment />}
                  />
                </PopoverTrigger>
                <PopoverContent bg="gray.100" borderColor="gray.800">
                  <PopoverArrow bg="gray.100" />
                  <PopoverCloseButton />
                  <PopoverHeader>Customer comment</PopoverHeader>
                  <PopoverBody>{item.comment}</PopoverBody>
                  <PopoverFooter
                    border="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb={4}
                  ></PopoverFooter>
                </PopoverContent>
              </Popover>
            )}
            <Popover placement="left">
              <PopoverTrigger>
                <IconButton
                  h="full"
                  colorScheme="orange"
                  icon={<BiBuildings />}
                />
              </PopoverTrigger>
              <PopoverContent
                color="white"
                bg="green.800"
                borderColor="green.800"
              >
                <PopoverArrow bg="green.800" />
                <PopoverCloseButton />
                <PopoverHeader>{item.vendor.name}</PopoverHeader>
                <PopoverBody>
                  <VendorContacts vendor={item.vendor} />
                </PopoverBody>
                <PopoverFooter
                  border="0"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  pb={4}
                ></PopoverFooter>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
      </Flex>
      {item.status === 'wrong' && (
        <Flex mt="10px" ml="20px" gap="20px">
          <Text fontWeight="bold" fontSize="sm">
            Customer comment
          </Text>
          <Text>{item.wrong}</Text>
        </Flex>
      )}
    </Box>
    // <Flex gap="20px" mb="10px">
    //   <Box w="50px" h="50px" bg="gray.100">
    //     {!!item.images.length && (
    //       <Image objectFit="cover" boxSize="50px" src={item.images[0].link} />
    //     )}
    //   </Box>
    //   <Box flex="1">{item.name}</Box>
    //   <Box textAlign="center" borderX="1px" borderColor="gray.300" px="20px">
    //     <Text>Order count</Text>
    //     <Text as="strong">{item.orderCount}</Text>
    //   </Box>
    //   <Box>
    //     {item.stockDate ? (
    //       <Box>
    //         <Tag w="full" colorScheme="teal">
    //           On stock
    //         </Tag>
    //       </Box>
    //     ) : (
    //       <Box mb="10px">
    //         <Tag w="full" colorScheme="green">
    //           {item.status}
    //         </Tag>
    //       </Box>
    //     )}
    //   </Box>
    // </Flex>
  );
};

export default ItemCard;
