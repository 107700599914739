import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Center,
  Select,
} from '@chakra-ui/react';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';

function SubCatEditForm() {
  const { catEdit } = useStore();

  return (
    <Modal
      isOpen={catEdit.subcatToEdit}
      onClose={() => catEdit.setSelectSubcat(null)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add sub category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {catEdit.subcatToEdit?.id && (
            <FormControl mb="20px">
              <FormLabel>Parent category</FormLabel>
              <Select
                name="parent"
                onChange={(e) => catEdit.setSubCatValue(e)}
                value={catEdit.subcatToEdit?.parent}
              >
                {catEdit.categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <FormLabel>Name RU</FormLabel>
            <Input
              name="name"
              value={catEdit.subcatToEdit?.name}
              onChange={(e) => catEdit.setSubCatValue(e)}
            />
          </FormControl>
          <FormControl my="20px">
            <FormLabel>Name EN</FormLabel>
            <Input
              name="name_en"
              value={catEdit.subcatToEdit?.name_en}
              onChange={(e) => catEdit.setSubCatValue(e)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Name TR</FormLabel>
            <Input
              name="name_tr"
              value={catEdit.subcatToEdit?.name_tr}
              onChange={(e) => catEdit.setSubCatValue(e)}
            />
          </FormControl>
          <Center my="20px">
            <Button
              colorScheme="green"
              isLoading={catEdit.isLoading}
              onClick={() => catEdit.addSubCat()}
            >
              Save
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default observer(SubCatEditForm);
