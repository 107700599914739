import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

const useMessage = (error, type = 'error', removeError) => {
  const toast = useToast();

  const message = error?.response?.data?.message || error?.message;
  useEffect(() => {
    if (error) {
      toast({
        title: message || 'Что-то пошло не так.',
        //description: message,
        status: type, //error, success, warning, info
        duration: 9000,
        isClosable: true,
      });

      setTimeout(() => removeError(null), 9000);
    }
  }, [error]);
};

export default useMessage;
