import $api from '../http';

export default class CrmService {
  // Прописать методы
  static async getOrders(params) {
    return $api.get('/crm/orders', { params });
  }

  static async getOrder(id) {
    return $api.get(`/crm/order/${id}`);
  }

  static async getVendors(status = '') {
    return $api.get('/crm/vendors', { params: { status } });
  }

  static async getVendor(id) {
    return $api.get(`/crm/vendor/${id}`);
  }

  static async updateVendor(id, data) {
    return $api.put(`/crm/vendor/${id}?q=update`, { ...data });
  }

  static async confirmVendor(id) {
    return $api.put(`/crm/vendor/${id}`, { status: 'active' });
  }

  static async getVendorItems(vid, params) {
    return $api.get(`/crm/vendor/${vid}/items`, { params });
  }
  static async showHideItem(id, vid, show) {
    return $api.put(`/crm/vendor/${vid}/item/${id}?show=${show}`);
  }

  static async manageOrder(orderId) {
    return $api.get(`/crm/manage/${orderId}`);
  }

  static async sendBill(orderId, data = {}) {
    return $api.post(`crm/bill/${orderId}`, data);
  }

  static async checkPay(orderId) {
    return $api.get(`crm/checkpay/${orderId}`);
  }

  static async setOrderStatus(orderId) {
    return $api.put(`/crm/manage/`);
  }

  static async getStockImages(id) {
    return $api.get(`/orders/stock/images/${id}`);
  }

  static async getPayData() {
    return $api.get(`/crm/paydata`);
  }

  static async fixItem(id) {
    return $api.get(`/crm/fixitem/${id}`);
  }

  static async updateLink(id, data = {}) {
    return $api.post(`/crm/updatelink/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  static async sendItem(id, data = {}) {
    return $api.post(`/crm/senditem/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
