import { Box, Flex, IconButton, Link } from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import React from 'react';
import { Link as rLink } from 'react-router-dom';

export default function CatViewList({ category, store }) {
  return (
    <Box p="10px" borderRadius="10px" bg="white" flex="1">
      <Flex gap="10px" alignItems="center">
        <Box flex="1">{category.name} </Box>
        <Box as="strong" color="gray.600">
          ({category.items})
        </Box>
        <IconButton
          size="xs"
          aria-label="Edit category"
          icon={<EditIcon />}
          onClick={() => store.catSelect(category.id)}
        />
        <IconButton
          colorScheme="green"
          size="xs"
          aria-label="Add subcategory"
          icon={<AddIcon />}
          onClick={() => store.setSelectSubcat({ parent: category.id })}
        />
      </Flex>
      <Box ml="10px" size="sm" mt="20px">
        {category.subcategory.map((sub) => (
          <Flex
            gap="10px"
            p="5px"
            bg="gray.50"
            fontSize="sm"
            mb="10px"
            key={sub.id}
          >
            <Link as={rLink} to={`/category/${category.id}/${sub.id}`} flex="1">
              {sub.name}
            </Link>
            <Box>{sub.items}</Box>
            <IconButton
              size="xs"
              aria-label="Edit subcategory"
              icon={<EditIcon />}
              onClick={() => store.subcatSelect(sub.id, category.id)}
            />
            {!sub.items && (
              <IconButton
                colorScheme="red"
                size="xs"
                aria-label="Delete subcategory"
                icon={<DeleteIcon />}
                onClick={() => store.deleteSubCat(sub.id)}
              />
            )}
          </Flex>
        ))}
      </Box>
    </Box>
  );
}
