import React from 'react';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useStore } from '../hooks/useStore';

const NoAccess = () => {
  const { store } = useStore();
  return (
    <Box w="full" px="8px" mt={['15px', '20px', '30px', '40px']}>
      <Box minH="90vh">
        <Flex
          maxW="1100px"
          alignItems="center"
          gap="20px"
          direction="column"
          px={['10px', '10px', '20px', '20px']}
          mx="auto"
        >
          <Box>You are not manager!</Box>
          <Box>
            <Button onClick={() => store.logout()} colorScheme="green">
              Logout
            </Button>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default NoAccess;
