import React from 'react';
import { Box, Flex, Text, Heading, Link, Divider } from '@chakra-ui/react';

const VendorData = ({ vendor }) => {
  return (
    <>
      <Flex gap="20px" flexDirection={['column', 'column', 'row', 'row']}>
        {vendor?.description && <Box flex="1">{vendor?.description}</Box>}
        <Box>
          <Flex gap="20px">
            <Text w="130px">Legal entity type: </Text>
            <Text as="strong">{vendor?.ur_type}</Text>
          </Flex>
          <Flex gap="20px">
            <Text w="130px">INN:</Text>
            <Text as="strong">{vendor?.inn}</Text>
          </Flex>
          <Flex gap="20px">
            <Text w="130px">Current account:</Text>
            <Text as="strong">{vendor?.rs}</Text>
          </Flex>
          {/* <Flex gap="20px">
            <Text w="130px">Tax Committee:</Text>
            <Text as="strong">{vendor?.tax_com}</Text>
          </Flex> */}
        </Box>
      </Flex>
      <Divider my="20px" />

      <Flex
        justifyContent="space-between"
        flexDirection={['column', 'column', 'row', 'row']}
        gap={['20px', '20px', 0, 0]}
      >
        <Box>
          {vendor?.site && (
            <Flex gap="20px">
              <Box>Website</Box>
              <Link
                as="strong"
                color="teal.500"
                href={`http://${vendor?.site}`}
                isExternal
              >
                {vendor?.site}
              </Link>
            </Flex>
          )}
          <Flex gap="20px">
            <Box>E-mail</Box>
            <Box as="strong" color="teal.500">
              {vendor?.email}
            </Box>
          </Flex>
          {vendor?.tel && (
            <Flex gap="20px">
              <Box>Phone</Box>
              <Link as="strong">{vendor?.tel}</Link>
            </Flex>
          )}
          {vendor?.mobile_tel && (
            <Flex gap="20px">
              <Box>Mobile phone</Box>
              <Link as="strong">{vendor?.mobile_tel}</Link>
            </Flex>
          )}
        </Box>

        <Box>
          <Heading size="sm">Address</Heading>
          <Box>
            {vendor?.country}, {vendor?.city}, {vendor?.district},{' '}
            {vendor?.index}
          </Box>
          <Flex gap="20px">
            <Box>Actual</Box>
            <Box as="strong">{vendor?.actual_adress}</Box>
          </Flex>
          <Flex gap="20px">
            <Box>Registered</Box>
            <Box as="strong">{vendor?.registered_adress}</Box>
          </Flex>
        </Box>

        <Box>
          <Heading size="sm">CEO</Heading>
          <Flex gap="20px">
            <Box>Name</Box>
            <Box as="strong">{vendor?.ceo_name}</Box>
          </Flex>
          <Flex gap="20px">
            <Box>Phone</Box>
            <Box as="strong">{vendor?.ceo_tel}</Box>
          </Flex>
        </Box>
        {vendor?.manager_name && vendor?.manager_tel && (
          <Box>
            <Heading size="sm">Manager</Heading>
            <Flex gap="20px">
              <Box>Name</Box>
              <Box as="strong">{vendor?.manager_name}</Box>
            </Flex>
            <Flex gap="20px">
              <Box>Phone</Box>
              <Box as="strong">{vendor?.manager_tel}</Box>
            </Flex>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default VendorData;
