import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useRoutes from './routes/useRoutes';
import Header from './components/Header';
import useMessage from './hooks/useMessage';
import { useStore } from './hooks/useStore';

function App() {
  const { store } = useStore();
  const routes = useRoutes(store.isAuth, store.isManager);

  useEffect(() => {
    if (localStorage.getItem('ctk')) {
      store.checkAuth();
    }
  }, []);

  useMessage(store.error, 'error', store.setError);

  return (
    <>
      <Header />
      {routes}
    </>
  );
}

export default observer(App);
