import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  ModalCloseButton,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';

const BillForm = ({ order, isOpen, onClose }) => {
  const { store } = useStore();

  const [card, setCard] = useState('');
  const [message, setMessage] = useState('');
  const [pay, setPay] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    store.getPayData();
  }, []);

  useEffect(() => {
    if (pay) {
      if (pay === 'card') {
        setData({ card, message });
      } else {
        setData({ payId: store.payData[pay].id });
      }
    }
  }, [pay, card, message]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send bill</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            size="sm"
            mb="20px"
            placeholder="Select pay data"
            onChange={(e) => setPay(e.target.value)}
          >
            {store.payData.map((item, idx) => (
              <option key={item.id} value={idx}>
                {item.name}
              </option>
            ))}
            <option value="card">Enter card data</option>
          </Select>
          {pay && pay !== 'card' ? (
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{store.payData[pay].name}</Td>
                  </Tr>
                  <Tr>
                    <Td>INN</Td>
                    <Td>{store.payData[pay].INN}</Td>
                  </Tr>
                  <Tr>
                    <Td>OGRN</Td>
                    <Td>{store.payData[pay].OGRN}</Td>
                  </Tr>
                  <Tr>
                    <Td>RS</Td>
                    <Td>{store.payData[pay].RS}</Td>
                  </Tr>
                  <Tr>
                    <Td>BIK</Td>
                    <Td>{store.payData[pay].BIK}</Td>
                  </Tr>
                  <Tr>
                    <Td>Kor S</Td>
                    <Td>{store.payData[pay].KorS}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            pay === 'card' && (
              <>
                <Input
                  placeholder="Enter card number"
                  value={card}
                  onChange={(e) => setCard(e.target.value)}
                />
                <Textarea
                  placeholder="Message"
                  mt="20px"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </>
            )
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            size="sm"
            colorScheme="teal"
            onClick={() => store.sendBill(order.id, data)}
            isLoading={store.loading}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default observer(BillForm);
