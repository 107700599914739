import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  HStack,
} from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';

function SendForm({ item, opened, onClose }) {
  const { store } = useStore();

  const [code, setCode] = useState('');
  return (
    <>
      <Modal isOpen={opened} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add send iteminformation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Link href={item.link} target="_blank">
              <HStack>
                <LinkIcon />
                <Text fontSize="xs" color="blue" noOfLines={1} w="250px">
                  {item.link}
                </Text>
              </HStack>
            </Link>
            <FormControl>
              <FormLabel>Add send code</FormLabel>
              <Input
                placeholder="Enter send code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={store.loading}
              onClick={() =>
                store.updateLink(item.id, { code }, () => onClose(false))
              }
            >
              Confirm
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onClose(false);
                setCode('');
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default observer(SendForm);
