import React, { useEffect } from 'react';
import { useParams, Link as rLink } from 'react-router-dom';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Heading,
  Divider,
  Flex,
  Tag,
  Center,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import VendorData from './VendorData';
import VendorItems from './VendorItems';

export const tag = {
  created: 'orange',
  active: 'green',
  blocking: 'red',
};

const VendorsViewPage = () => {
  const { id } = useParams();
  const { store } = useStore();
  useEffect(() => {
    store.getVendor(id);
  }, []);

  return (
    <>
      <Box p={['15px', '15px', '30px', '30px']} bg="white" borderRadius="md">
        <Flex gap="20px" flexDirection={['column', 'column', 'row', 'row']}>
          <Heading size="md">{store.vendor?.name}</Heading>
          <Flex gap="20px" flex="1">
            <Box flex="1">
              <Tag colorScheme={tag[store.vendor?.status]} borderRadius="full">
                {store.vendor?.status}
              </Tag>
            </Box>
            <Box>
              {/* <Button size="xs" mr="10px" colorScheme="red">
              Vendor items
            </Button> */}
              <Button
                as={rLink}
                to={`/vendor/${store.vendor?.mid}/edit`}
                size="xs"
                colorScheme="red"
                leftIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          </Flex>
        </Flex>
        <Divider my="20px" />

        <VendorData vendor={store.vendor} />
        {!(store.vendor?.status === 'active') && (
          <Center mt="30px">
            <Button
              colorScheme="green"
              isLoading={store.loading}
              onClick={() => store.confirmVendor(id)}
            >
              Confirm vendor
            </Button>
          </Center>
        )}
      </Box>

      <Box
        p={['15px', '15px', '30px', '30px']}
        bg="white"
        borderRadius="md"
        mt="30px"
      >
        <VendorItems vid={id} />
      </Box>
    </>
  );
};

export default observer(VendorsViewPage);
