import React from 'react';
import {
  Box,
  Heading,
  Divider,
  Accordion,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react-lite';
import OrderCard from './OrderCard';

const OrdersPage = () => {
  const { store } = useStore();
  console.log(store);
  useEffect(() => {
    store.getOrders();
    return () => {
      store.setOrders([]);
    };
  }, []);

  if (!store.orders.length && store.loading)
    return (
      <Center flex="1">
        <Spinner thickness="4px" size="xl" color="green" />
      </Center>
    );
  return (
    <Box p="30px" borderRadius="md" bg="white">
      <Heading mb="30px">Orders</Heading>
      <Accordion allowToggle>
        {store.orders.map((order) => (
          <OrderCard key={order.id} order={order} />
        ))}
      </Accordion>
    </Box>
  );
};

export default observer(OrdersPage);
