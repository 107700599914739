import { AxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';
import AuthService from '../service/AuthService';

const hashCode = function (s) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export default class RegisterUser {
  loading = false;
  email = '';
  code = '+7';
  phone = '';
  password = '';
  phoneVefify = false;
  smsCode = null;
  isEnterCode = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  setEmail(email) {
    this.email = email;
  }

  setCode(code) {
    this.code = code;
  }
  setPhone(phone) {
    this.phone = phone;
  }

  setPassword(password) {
    this.password = password;
  }

  setEnterCode(bool) {
    this.isEnterCode = bool;
  }

  setError(err) {
    this.error = err;
  }

  setLoading(bool) {
    this.loading = bool;
  }

  setSmsCode(code) {
    this.smsCode = code;
  }

  setVerify(bool) {
    this.phoneVefify = bool;
  }

  async getCheckPhone(type = 'sms') {
    let ansver;
    switch (type) {
      case 'sms':
        ansver = await AuthService.getSmsCode(this.code + this.phone);
        break;

      case 'call':
        ansver = await AuthService.getCallCode(this.code + this.phone);
        break;

      case 'email':
        ansver = await AuthService.getEmailCode(this.email);
        break;
    }
    const { data } = ansver;
    return data.code;

    // const { data } = await AuthService.getSmsCode(this.code + this.phone);
    // return data.code;
  }

  async checkPhone(smsCode) {
    this.setLoading(true);
    try {
      const code = hashCode(smsCode);
      if (code === this.smsCode) {
        this.setVerify(true);
        await this.register();
        this.setEnterCode(false);
      }
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async checkUser() {
    try {
      const { data } = await AuthService.checkUser({
        email: this.email,
        phone: this.code + this.phone,
      });
      return data?.find;
    } catch (e) {
      this.setError(e);
    }
  }

  async register() {
    this.setLoading(true);
    try {
      if (this.phoneVefify) {
        const { data } = await AuthService.register(
          this.email,
          this.code + this.phone,
          this.password
        );
        localStorage.setItem('ctk', data.accessToken);
      } else {
        const checkuser = await this.checkUser();
        if (checkuser) {
          throw new AxiosError('User already exists');
        }
        const code = await this.getCheckPhone();
        this.setSmsCode(code);
        this.setEnterCode(true);
      }
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }

  async resendCode(type = 'call') {
    this.setLoading(true);
    try {
      this.setSmsCode(await this.getCheckPhone(type));
    } catch (e) {
      this.setError(e);
    } finally {
      this.setLoading(false);
    }
  }
}
