import $api from '../http';

export default class CatEditService {
  // Прописать методы
  static async getAllCategories() {
    return $api.get('/crm/categories');
  }

  static async updateCategory(id, $params) {
    return $api.put(`/crm/category/${id}`, $params);
  }

  static async addSubCategory($params) {
    return $api.post(`/crm/category/subcategory`, $params);
  }

  static async getSubCategoryItems(id) {
    return $api.get(`/crm/subcat/${id}/items`);
  }

  static async moveItems(data) {
    return $api.post(`/crm/moveitems`, data);
  }
  static async deleteSubCat(data) {
    return $api.delete(`/crm/category/subcategory`, { data });
  }
}
