import {
  Box,
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Text,
  Flex,
  Spinner,
  Center,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useStore } from '../hooks/useStore';
import VendorItemCard from './VendorItemCard';
import Pagination from './Pagination';

export default function VendorItems({ vid }) {
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const { store } = useStore();

  const getItems = async (search = {}) => {
    setLoading(true);
    const { items, count } = await store.getVendorItems(vid, search);
    if (items) {
      setItems(items);
      setCount(count);
      setLoading(false);
    }
  };

  const showHideItem = async (id, vid, show) => {
    const answer = await store.publicateItem(id, vid, show);
    if (answer) {
      const updateItemIdx = items.findIndex((el) => el.id === id);
      if (updateItemIdx !== -1) {
        const changedItems = items.map((el, idx) => {
          if (idx === updateItemIdx) {
            el.show = show;
          }
          return el;
        });
        setItems(changedItems);
      }
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Flex
              flex="1"
              justifyContent="center"
              gap="20px"
              textAlign="center"
            >
              <Box as="b">Vendor Items</Box>

              <Text as="span" color="gray.600" fontWeight="bold">
                {count}
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {loading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            items.map((el) => (
              <VendorItemCard
                key={el.id}
                item={el}
                pub={(e) => {
                  e.preventDefault();
                  showHideItem(el.id, el.vid, !el.show);
                }}
              />
            ))
          )}

          <Pagination
            callback={(...args) => getItems(...args)}
            itemsCount={count}
            isLoading={loading}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
